exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contacto-js": () => import("./../../../src/pages/contacto.js" /* webpackChunkName: "component---src-pages-contacto-js" */),
  "component---src-pages-eventos-js": () => import("./../../../src/pages/eventos.js" /* webpackChunkName: "component---src-pages-eventos-js" */),
  "component---src-pages-foto-y-video-js": () => import("./../../../src/pages/foto-y-video.js" /* webpackChunkName: "component---src-pages-foto-y-video-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-servicios-js": () => import("./../../../src/pages/servicios.js" /* webpackChunkName: "component---src-pages-servicios-js" */),
  "component---src-pages-sonido-y-luces-js": () => import("./../../../src/pages/sonido-y-luces.js" /* webpackChunkName: "component---src-pages-sonido-y-luces-js" */)
}

